<template>
    <section class="bg-secondary section-item py-3 py-lg-7">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-4 text-white fl-x fl-a-c mb-5 mb-lg-0 mt-5">
                    <p class="mb-0 font-weight-bold text-justify pr-lg-3">
                        Enchance The Efficiency and Transparency of Procurement and Contract Managemnet Process of your Organisation
                    </p>
                </div>
                <div class="col-12 col-lg-8 fl-x fl-a-e">
                    <div class="row">
                        <div data-aos="fade-up" data-aos-duration="500"
                             class="col-12 col-xl-4 col-md-6 text-white mb-5">
                            <feature-item heading="Customisable">
                                <template #icon>
                                    <img class="img-fluid w-25p mb-3" alt="#" src="../../assets/img/works/works-01.png">
                                </template>
                                <template #content>
                                    <p class="mb-0">Prepare contracts as per your specific organisational
                                        requirements</p>
                                </template>
                            </feature-item>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="500"
                             class="col-12 col-xl-4 col-md-6 text-white mb-5">
                            <feature-item heading="Open Platform">
                                <template #icon>
                                    <img class="img-fluid w-25p mb-3" alt="#" src="../../assets/img/works/works-02.png">
                                </template>
                                <template #content>
                                    <p class="mb-0">Tendering platform for organisations & individuals</p>
                                </template>
                            </feature-item>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="500"
                             class="col-12 col-xl-4 col-md-6 text-white mb-5">
                            <feature-item heading="Visibility">
                                <template #icon>
                                    <img class="img-fluid w-25p mb-3" alt="#" src="../../assets/img/works/works-03.png">
                                </template>
                                <template #content>
                                    <p class="mb-0">Online presence substantially improves visibility to prospective
                                        clients </p>
                                </template>
                            </feature-item>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="500"
                             class="col-12 col-xl-4 col-md-6 text-white mb-5 mb-xl-0">
                            <feature-item heading="Transparency">
                                <template #icon>
                                    <img class="img-fluid w-25p mb-3" alt="#" src="../../assets/img/works/works-04.png">
                                </template>
                                <template #content>
                                    <p class="mb-0">Greater transparency & accountability for your tendering,
                                        procurement & contract management process</p>
                                </template>
                            </feature-item>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="500"
                             class="col-12 col-xl-4 col-md-6 text-white mb-5 mb-lg-0">
                            <feature-item heading="Standardisation">
                                <template #icon>
                                    <img class="img-fluid w-25p mb-3" alt="#" src="../../assets/img/works/works-05.png">
                                </template>
                                <template #content>
                                    <p class="mb-0">Standardised templates for easy preparation of high quality
                                        professional contract documents</p>
                                </template>
                            </feature-item>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="500"
                             class="col-12 col-xl-4 col-md-6 text-white mb-5 mb-lg-0">
                            <feature-item heading="Accessibility">
                                <template #icon>
                                    <img class="img-fluid w-25p mb-3" alt="#" src="../../assets/img/works/works-06.png">
                                </template>
                                <template #content>
                                    <p class="mb-0">Cloud based and hence can be accessed, reviewed, and updated from
                                        anywhere</p>
                                </template>
                            </feature-item>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import FeatureItem from '@/views/components/UI/FeatureItem';

export default {
    name: 'FeaturesSection',
    components: { FeatureItem }
};
</script>

<style lang="scss" scoped>

.bg-sec-700 {
    background-color: var(--color-secondary-700);
}

//.section-item {
//    height: auto;
//    @media (min-width: 992px) {
//        height: 50vh;
//    }
//}

.side-info {
    height: auto;
    @media (min-width: 992px) {
        height: 86.5%;
    }
}

.bottom-bar {
    background-color: var(--color-primary);
    height: 0.3125rem;
    width: 3.125rem;
    //top: -1.375rem;
    left: 0;
    margin: 0.9rem 0;
}

</style>
