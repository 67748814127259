<template>
    <div>
        <InnerBanner bg="main" heading="Knowledge Hub"/>
        <section class="py-5">
            <div class="get-in-touch">
                <div class="container">
                    <div class="row fl-y-c py-3">
                        <div class="col-10 text-center get-in-text">
                            <h2 class="font-weight-bold">Knowledge Hub</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row fl-j-c">
                    <div class="col-lg-10">
                        <vsa-list class="w-100 c-accordion">

                            <vsa-item v-for="item in items" :key="item">>
                                <vsa-heading>
                                    <div class="fl-none">{{ item.sl_no }} .</div>
                                    <div class="pl-1"> {{ item.question }}</div>
                                </vsa-heading>

                                <vsa-icon>
                                    <span class="open fs-9 font-weight-bold text-secondary mr-3 text-primary-h">+</span>
                                    <span
                                        class="close fs-9 font-weight-bold text-secondary mr-3 text-primary-h">-</span>
                                </vsa-icon>

                                <vsa-content>
                                    <div class="">
                                        <p class="">{{ item.answer }}</p>
                                    </div>
                                </vsa-content>

                            </vsa-item>

                            <!--                            <vsa-item>-->
                            <!--                                <vsa-heading>-->
                            <!--                                    New to Construction ?-->
                            <!--                                </vsa-heading>-->

                            <!--                                <vsa-icon>-->
                            <!--                                    <span class="open fs-9 font-weight-bold text-secondary mr-3 text-primary-h">+</span>-->
                            <!--                                    <span-->
                            <!--                                        class="close fs-9 font-weight-bold text-secondary mr-3 text-primary-h">-</span>-->
                            <!--                                </vsa-icon>-->

                            <!--                                <vsa-content>-->
                            <!--                                    <div>-->
                            <!--                                        <p class="py-4">New to Construction ?? Pit Falls to be avoided, Lorem ipsum dolor-->
                            <!--                                            sit amet, consectetur adipisicing elit. Atque, cumque deserunt doloremque-->
                            <!--                                            earum eius, eum facilis ipsam iste laudantium, minima minus odit qui-->
                            <!--                                            quibusdam repudiandae sapiente sequi similique vel veniam!</p>-->
                            <!--                                    </div>-->
                            <!--                                </vsa-content>-->

                            <!--                            </vsa-item>-->

                            <!--                            <vsa-item>-->
                            <!--                                <vsa-heading>-->
                            <!--                                    Procurement Strategies-->
                            <!--                                </vsa-heading>-->

                            <!--                                <vsa-icon>-->
                            <!--                                    <span class="open fs-9 font-weight-bold text-secondary mr-3 text-primary-h">+</span>-->
                            <!--                                    <span-->
                            <!--                                        class="close fs-9 font-weight-bold text-secondary mr-3 text-primary-h">-</span>-->
                            <!--                                </vsa-icon>-->

                            <!--                                <vsa-content>-->
                            <!--                                    <div class="">-->
                            <!--                                        <p class="">New to Construction ?? Pit Falls to be avoided, Lorem ipsum dolor-->
                            <!--                                            sit amet, consectetur adipisicing elit. Atque, cumque deserunt doloremque-->
                            <!--                                            earum eius, eum facilis ipsam iste laudantium, minima minus odit qui-->
                            <!--                                            quibusdam repudiandae sapiente sequi similique vel veniam!</p>-->
                            <!--                                    </div>-->
                            <!--                                </vsa-content>-->
                            <!--                            </vsa-item>-->

                        </vsa-list>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import {
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
    VsaIcon
} from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';
import InnerBanner from '@/views/components/Inner-banner';
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name: 'Knowledge hub',
    components: {
        InnerBanner,
        VsaList,
        VsaItem,
        VsaHeading,
        VsaContent,
        VsaIcon
    },

    metaInfo: {
        title: 'Knowledge hub ',
        titleTemplate: 'ContractAce | %s',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: ' At ContractAce, we enable even small and medium size companies to avail the benefits of e-tendering and in leveraging the power of the internet.'
            },
            {
                name: 'keywords',
                content: 'contractace, eprocurement, e-procurement, contract management services in India, contract management, procurement, procurement software in India, construction software, procurement process in India, procurement service , e-tendering, e-tenders'
            }
        ]
    },

    data () {
        return {
            items: []
        };
    },
    mounted () {
        this.loadList();
    },
    methods: {
        async loadList () {
            const response = await axios.get(urls.admin.KnowledgeHub.List);
            this.items = response.data.faq_data;
        }
    }
};
</script>

<style scoped lang="scss">
.get-in-touch {
    position: relative;
    //margin-bottom: 150px;

    svg {
        position: absolute;
        width: 100%;
        //left: 0;
        //bottom: 0;
    }

    .get-in-text {

    }
}

.vsa-item__content {
    p {
        padding: 1.5rem 0;
        margin: 0;
    }
}

.vsa-item__trigger__content {
    text-align: left;
}
</style>
