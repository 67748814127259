<template>
    <div class="full-width">
        <header class="text-white item-container">
            <i :class="icon" class="mb-2 color-primary" v-if="icon !==''"></i>
            <slot name="icon" v-else></slot>
            <h4 class="montserrat-medium">{{ heading }}</h4>

            <slot name="content">
                <p class="text-left">Your contents here</p>
            </slot>
        </header>
    </div>

</template>

<script>
export default {
    name: 'FeatureItem',
    props: {
        icon: {
            type: String,
            default: ''
        },
        heading: {
            type: String,
            default: 'Heading'
        },
        content: {
            type: String,
            default: 'Contents here'
        }
    }
};
</script>

<style lang="scss" scoped>
.b-shadow {
    border-radius: 20px;
    /*background: var(--color-secondary-700);*/
    box-shadow: 5px 5px 16px #2a2a2a,
    -5px -5px 16px var(--color-secondary-700);
}

.m-h-md {
    @media (min-width: 992px) {
        min-height: 16.8125rem;
    }
}

.item-container {
    text-align: center;
    @media (min-width: 992px) {
        text-align: left;
    }
}
</style>
