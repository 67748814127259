<template>
    <div class="row mt-5">
        <div class="col-12">
            <short-section heading="Other Services">
                <template #content>
                    <ul class="ml-4">
                        <li>
                            <router-link to="/Client-Service-Detials/">Client Services</router-link>
                        </li>
                        <li>
                            <router-link to="/Consultant-Service-Detials/">Consultant Services</router-link>
                        </li>
                        <li>
                            <router-link to="/Contractors-Service-Detials/">Contractor Services</router-link>
                        </li>
                        <li>
                            <router-link to="/Suppliers-Service-Detials/">Supplier Services</router-link>
                        </li>
                        <li>
                            <router-link to="/ContractManagers-Service-Detials/">Contract Manager Services
                            </router-link>
                        </li>
                    </ul>
                </template>
            </short-section>
        </div>
    </div>
</template>

<script>
import ShortSection from '@/views/components/UI/ShortSection';

export default {
    name: 'Otherservices',
    components: { ShortSection }
};
</script>

<style scoped>
.router-link-exact-active {
    font-weight: 600;
    color: var(--color-primary);
}
</style>
