import { render, staticRenderFns } from "./DetailsInner.vue?vue&type=template&id=6566b0dd&scoped=true&"
import script from "./DetailsInner.vue?vue&type=script&lang=js&"
export * from "./DetailsInner.vue?vue&type=script&lang=js&"
import style0 from "./DetailsInner.vue?vue&type=style&index=0&id=6566b0dd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6566b0dd",
  null
  
)

export default component.exports