<template>
    <div class="bg-third of-h">
        <InnerBanner bg="main" heading="Training Videos"/>

        <section class="py-5">
            <!--            <div class="get-in-touch mb-4">-->
            <!--                <div class="container">-->
            <!--                    <div class="row fl-y-c py-3">-->
            <!--                        <div class="col-10 text-center get-in-text">-->
            <!--                            <h2 class="font-weight-bold">Training Videos</h2>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->

            <div class="container">

                <div class="row">
                    <CoolLightBox key="box-1"
                                  :class="{'c-cool-lightbox-align-fix-start': lightboxStarAlign,
                                    'c-cool-lightbox-align-fix-end': lightboxEndAlign,
                                    'c-cool-lightbox-align-fix-mid': lightboxMidAlign}"
                                  :items="items"
                                  :index="index"
                                  @on-open="lightboxOpen = true"
                                  @on-change="lightboxOpen = false"
                                  @close="closeLightbox">
                    </CoolLightBox>
                    <!--                    <div class="card">-->
                    <!--                        <div-->
                    <!--                            class="bg-primary text-secondary"-->
                    <!--                            v-for="(image, imageIndex) in items"-->
                    <!--                            :key="imageIndex"-->
                    <!--                            @click="index = imageIndex"-->
                    <!--                            :style="{ backgroundImage: 'url(' + image.src + ')' }"-->
                    <!--                        >-->
                    <!--                            rterterfergf-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                    <div class="col-12 mb-4" v-for="(image, imageIndex) in items" :key="imageIndex" data-aos="fade-up"
                         data-aos-duration="500">
                        <a class="card b-1 d-block c-pointer bg-secondary bs-1 h-100" @click="index = imageIndex">
                            <div class="row">
                                <div class="col-md-4 fl-eq-c mb-3 mb-md-0">
                                    <!--                                    <img :src="image.thumb" class="img-fluid d-block" alt="#">-->
                                    <iframe height="200" class="w-100p" style="pointer-events: none" :src="image.src"
                                            title="YouTube video player" frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen></iframe>

                                </div>
                                <div class="col-md-8">
                                    <p class="fs-2 font-weight-bold"><span>{{ imageIndex + 1 }}</span>.
                                        {{ image.title }}
                                    </p>
                                    <p v-if="image.para1 !==''">
                                        {{ image.para1 }}
                                    </p>
                                    <p v-if="image.para2 !==''">
                                        {{ image.para2 }}
                                    </p>

                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

import InnerBanner from '@/views/components/Inner-banner';
// import img1 from '../assets/img/training/1.png';
// import img2 from '../assets/img/training/2.png';
// import img3 from '../assets/img/training/3.png';
// import img4 from '../assets/img/training/4.png';
// import img5 from '../assets/img/training/5.png';
// import img6 from '../assets/img/training/6.png';
// import img7 from '../assets/img/training/7.png';

import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name: 'Training',
    components: {
        InnerBanner
    },
    metaInfo: {
        title: 'Training videos ',
        titleTemplate: 'ContractAce | %s',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: ' At ContractAce, we enable even small and medium size companies to avail the benefits of e-tendering and in leveraging the power of the internet.'
            },
            {
                name: 'keywords',
                content: 'contractace, eprocurement, e-procurement, contract management services in India, contract management, procurement, procurement software in India, construction software, procurement process in India, procurement service , e-tendering, e-tenders'
            }
        ]
    },
    data: function () {
        return {
            training_items: [],
            lightboxOpen: false,
            items: [],
            index: null
        };
    },
    mounted () {
        this.loadDetails();
    },
    computed: {
        lightboxStarAlign () {
            console.log('index', this.index, this.items.length / 3);
            return this.lightboxOpen && this.index < this.items.length / 3;
        },
        lightboxMidAlign () {
            console.log('index', this.index, this.items.length / 1.5);
            return this.lightboxOpen && this.index >= this.items.length / 3 && this.index < this.items.length / 1.5;
        },
        lightboxEndAlign () {
            console.log('index', this.index);
            return this.lightboxOpen && this.index >= this.items.length / 1.5 && this.index < this.items.length;
        }
    },
    methods: {
        async loadDetails () {
            const response = await axios.get(urls.admin.TrainingVideo.List);
            this.items = response.data.video_data;
        },
        closeLightbox () {
            console.log('onOpenLightbox');
            this.index = null;
            this.lightboxOpen = false;
        }
    }
};
</script>

<style scoped lang="scss">
//.o-h-visible{
//    overflow-x: visible;
//}
</style>
