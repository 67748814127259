<template>
    <div class="about">
        <InnerBanner bg="main" heading="About Us"/>
        <details-inner
            heading="">
            <template #content>
                <p>
                    At ContractAce it is our endeavour to ensure that the benefits of e-tendering & professional
                    contract management services are available to all organisations and individuals. The platform
                    provides a full 360 degree solution for Tendering, Contract Management & Procurement. The open
                    architecture of the platform is designed to ensure that it is suitable for preparation of complex
                    engineering contracts.
                    <br><br>
                    The platform is designed to assist organisations to streamline and substantially improve the
                    efficiency of their contract management and procurement process. The platform is also helpful for
                    individuals and organisations whose core expertise is not in construction but wish to undertake
                    construction projects for their own needs.
                </p>
            </template>
        </details-inner>
    </div>
</template>
<script>

import InnerBanner from '@/views/components/Inner-banner';
import DetailsInner from '@/views/components/UI/DetailsInner';

export default {
    name: 'About',
    components: { DetailsInner, InnerBanner },
    metaInfo: {
        title: 'Contract Management Expert |',
        titleTemplate: 'ContractAce-About Us | %s',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: 'ContractAce assist organisations to streamline and substantially improve the efficiency of their contract management and procurement process.'
            },
            {
                name: 'keywords',
                content: 'contractace, eprocurement, e-procurement, contract management services in India, contract management , procurement service in India, procurement process , e-tendering in kerala, e-tenders, procurement, procurement software, construction software in India'
            }
        ]
    }
};
</script>

<style scoped>

</style>
