<template>
    <section class="py-5">
        <div class="container">
            <h1 class="montserrat-bold mb-3">{{ heading }}</h1>
            <section class="mb-4">
                <slot name="content"></slot>
            </section>
            <footer>
                <slot name="footer"></slot>
            </footer>
        </div>
    </section>
</template>

<script>
export default {
    name: 'DetailsInner',
    props: {
        heading: {
            type: String,
            default: 'heading'
        }
    }
};
</script>

<style lang="scss" scoped>
footer {

    a {
        text-decoration: none;
    }

}
</style>
