<template>
    <div class="mt-3 mt-lg-0 heading-container" data-aos="fade-up"
                 data-aos-duration="500">
        <h4 class="montserrat-bold" :class="{'lg-heading': titleSize === 'lg'}" v-if="heading !== ''" >{{ heading }}</h4>
        <slot name="header" v-else></slot>
        <span class="bottom-bar" :class="{'bottom-bar-lg': titleSize === 'lg'}"></span>
        <slot name="content"></slot>
    </div>
</template>

<script>
export default {
    name: 'ShortSection',
    props: {
        heading: {
            type: String,
            default: ''
        },
        titleSize: {
            type: String,
            default: 'sm'
        }
    }
};
</script>

<style scoped lang="scss">

.heading-container {
    text-align: center;

    .bottom-bar {
        background-color: var(--color-primary);
        height: 0.3125rem;
        width: 3.125rem;
        margin: 0.9rem 0;
        display: inline-block;
    }
    .bottom-bar-lg{
        width:6.125rem;
    }

    @media (min-width: 992px) {
        text-align: left;
    }
}

</style>
