<template>
    <div>
        <InnerBanner bg="main" heading="Consultant"/>
        <details-inner heading="For Consultants">
            <template #content>
                <p>
                    Consultants providing engineering and commercial services to clients can register and create their own
                    space for e-tendering and procurement services. They can create their own suite of standard contract
                    templates which can be used for all their tendering needs. The documents can be made efficiently and
                    sent online to contractors for pricing. This will considerably reduce the paper and printing
                    requirements and there is a clear audit trail for the processes followed. The process of selecting
                    the contractors also becomes easier and more transparent.

                    <br><br>
                    ContractAce provides the following advantages to consultants:

                </p>
                <ul class="ml-4">
                    <li>
                        Online presence improves visibility to clients and increases chances of getting
                        more enquiries.
                    </li>
                    <li>
                        ContractAce provides an open and highly customisable e-tendering platform where one can design
                        templates as per specific needs.
                    </li>
                    <li>
                        Standardise documents to reduce errors and oversight in the contract, consequently reducing
                        risk of losses and disputes for clients.
                    </li>
                    <li>
                        Online process is highly transparent and can increase the confidence levels of client.
                    </li>
                    <li>
                        Historical data can be easily accessed and analysed, thus, consistently building the
                        organisation’s knowledge base.
                    </li>
                    <li>
                        The system being cloud based can be accessed from anywhere making it suitable for remote working.
                    </li>
                    <li>
                        ContractAce provides an open and highly customisable e-tendering / e-procurement platform where one can design templates as per specific needs
                    </li>
                </ul>
                <br><br>
                <p>The following options are suitable for consultants :</p>
                <ol class="ml-4">
                    <li><span class="montserrat-bold">Option 1 </span>:&nbsp; Create  tender documents from the standard templates
                        provided, send it to contractors for pricing, collect bids and prepare contracts.

                    </li>
                    <li><span class="montserrat-bold">Option-2</span>:&nbsp; Avail the services of our contract management expert to decide the best procurement strategy and create high quality, professional
                        standard contract document so as to protect the interests and reduce risk for the client.

                    </li>
                    <li><span class="montserrat-bold">Option-3</span> :&nbsp; :  Create  customised templates according to organisational needs,
                        which can be modified and used for making new contracts.

                    </li>
                    <li><span class="montserrat-bold">Option-4</span> :&nbsp; Avail the services of our contract
                        management expert to design and develop a suite of high quality professional standard templates that can be used for various projects. This can reduce the time and effort
                        needed to raise the quality of the contract management system to global standards.

                    </li>
                </ol>
            </template>
            <template #footer>
                <Otherservices/>
                <!--                <div class="row">-->
                <!--                    <div class="col">-->
                <!--                        <div class="fl-x fl-j-sb">-->
                <!--                            <router-link :to="{name:'Suppliers-Service-Detials'}">Prev</router-link>-->
                <!--                            <router-link :to="{name:'ContractManagers-Service-Detials'}">Next</router-link>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </div>-->
            </template>
        </details-inner>

    </div>
</template>

<script>
import DetailsInner from '@/views/components/UI/DetailsInner';
import InnerBanner from '@/views/components/Inner-banner';
import Otherservices from '@/views/components/Otherservices';

export default {
    name: 'ConsultantService',
    components: { Otherservices, InnerBanner, DetailsInner },
    metaInfo: {
        title: 'Consultant services',
        titleTemplate: 'ContractAce | %s',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: 'ContractAce provides a full 360 degree solution for Tendering, Contract Management & Procurement.'
            },
            {
                name: 'keywords',
                content: 'contractace, e-tendering in India, e-tenders, eprocurement, e-procurement, contract management services , contract management in India, procurement software, construction software, procurement service , procurement process in India'
            }
        ]
    }
};
</script>

<style scoped>

</style>
