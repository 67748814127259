<template>
    <div>
        <InnerBanner bg="main" heading="Services"/>

        <details-inner
            heading="">
            <template #content>
                <p>
                    ContractAce provides an open e-tendering and Contract Management platform for all individuals &
                    organisations. This includes online preparation of Tender Documents which can be done either from
                    scratch or using an existing template or earlier prepared tender can be copied and modified to suit
                    the project requirements.
                    <br><br>
                    Once the tender document is ready it can be sent online to selected contractors.  This can
                    be to a contractor familiar to the client, or can be chosen from a list of locally available
                    contractors.
                    <br><br>
                    On receiving the tender document, the contractors can fill in the prices and submit their bid.
                    The prices submitted by various contractors can then be analysed and the best option
                    selected. Once the contractor is selected and prices agreed, the documents can be printed and signed
                    to form an agreement. The platform also provides the option to prepare your own template at any
                    point of time and use it as a standard template for the organisation. Further, our experts can
                    develop & provide a set of professionally drafted contract templates as per your requirement. Once
                    the templates are finalised by the management, these can be copied and used to create high quality
                    professional contracts to suit specific project requirements. These templates have a modular
                    structure, enabling easy modification to suit a specific project. The platform caters to the
                    requirements of various types of organisations such as Clients, Consultants, Contractors, etc.

                </p>
            </template>
        </details-inner>

        <Servicesection/>

    </div>
</template>

<script>
import InnerBanner from '@/views/components/Inner-banner';
import DetailsInner from '@/views/components/UI/DetailsInner';
import Servicesection from '@/views/components/ServicesSection';

export default {
    name: 'Service',
    components: { Servicesection, DetailsInner, InnerBanner },
    metaInfo: {
        title: 'Your Contract Expert Management',
        titleTemplate: 'ContractAce-services | %s',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: 'ContractAce provides a full 360 degree solution for Tendering, Contract Management & Procurement.'
            },
            {
                name: 'keywords',
                content: 'contractace, e-tendering in India, e-tenders, eprocurement, e-procurement, contract management services , contract management in India, procurement software, construction software, procurement service , procurement process in India'
            }
        ]
    }
};
</script>

<style scoped>

</style>
