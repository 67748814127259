<template>
    <div>
        <InnerBanner bg="main" heading="Contract Managers"/>
        <details-inner
            heading="For Contract Managers, Procurement Managers and Freelancers">
            <template #content>
                <p>
                    The platform is designed to assist contract managers and procurement managers to design and develop
                    standard templates which can be used for providing services to remote clients and contractors. These
                    standard templates which can be designed as per local needs can significantly increase the
                    productivity of freelancers.
                    <br><br>
                    The advantages of ContractAce are as follows :

                </p>
                <ul class="ml-4">
                    <li>
                        Prepare documents and send it to remote clients who can import the same
                        and use it for tendering and/or for using as standard contract templates.
                    </li>
                    <li>
                        Online presence improves visibility to clients and increases chances of getting more freelance
                        jobs.
                    </li>
                    <li>
                        ContractAce provides an open and highly customisable e-tendering and e-procurement platform
                        where one can design templates as per specific needs.
                    </li>
                    <li>
                        High quality standard templates are available, which can improve the quality of the contract
                        documents.
                    </li>
                    <li>
                        Reduce errors and oversight in the contract, consequently reducing risk of losses and disputes
                        for clients.
                    </li>
                    <li>
                        Online process is highly transparent and can increase the confidence levels of clients.
                    </li>
                    <li>
                        Historical data can be easily accessed and analysed, thus, consistently building knowledge base.
                    </li>
                    <li>The system being cloud based, can be accessed from anywhere making it suitable for remote
                        working.
                    </li>
                </ul>
                <br>
                <p>The following options are suitable :</p>
                <ol class="ml-4">
                    <li><span class="montserrat-bold">Option-1</span>: Create tender documents from the standard
                        templates provided, send it for pricing, collect bids and prepare contracts.
                    </li>
                    <li><span class="montserrat-bold">Option-2</span>: Create customised template
                        according to the organisational needs, which can be modified and used for making new contracts.
                    </li>
                    <li>
                        <span class="montserrat-bold">Option-3</span>: Avail the services of our contract management
                        expert to design and develop a suite of high quality professional standard templates that can be
                        used for various projects. This can reduce the time and effort needed to raise the
                        quality of the contract management system to global standards.
                    </li>
                </ol>
            </template>
            <template #footer>
                <Otherservices/>
                <!--                <div class="row">-->
                <!--                    <div class="col">-->
                <!--                        <div class="fl-x fl-j-sb">-->
                <!--                            <router-link :to="{name:'Consultant-Service-Detials'}">-->
                <!--                                <nav-button title="For Consultants Providing Services to Construction Industry"-->
                <!--                                            type="prev"></nav-button>-->
                <!--                            </router-link>-->
                <!--                            <router-link :to="{name:'Contractors-Service-Detials'}">-->
                <!--                                <nav-button title="For Consultants Providing Services to Construction Industry"-->
                <!--                                            type="next"></nav-button>-->
                <!--                            </router-link>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </div>-->
            </template>
        </details-inner>
    </div>
</template>

<script>
import InnerBanner from '@/views/components/Inner-banner';
import DetailsInner from '@/views/components/UI/DetailsInner';
// import NavButton from '@/views/components/UI/NavButton';
import Otherservices from '@/views/components/Otherservices';

export default {
    name: 'ContractManagersService',
    components: {
        Otherservices,
        // NavButton,
        DetailsInner,
        InnerBanner
    },
    metaInfo: {
        title: 'Contract Manager services',
        titleTemplate: 'ContractAce | %s',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: 'ContractAce provides a full 360 degree solution for Tendering, Contract Management & Procurement.'
            },
            {
                name: 'keywords',
                content: 'contractace, e-tendering in India, e-tenders, eprocurement, e-procurement, contract management services , contract management in India, procurement software, construction software, procurement service , procurement process in India'
            }
        ]
    }
};
</script>

<style scoped>

</style>
