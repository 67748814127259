<template>
    <section class="py-3 py-lg-7">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-4">
                    <short-section title-size="lg"
                                   heading="WHAT CLIENTS SAY?">
                        <template #content>
                            <p class="mb-1">
                                Our aim is to ensure that the benefits of online tendering, procurement and professional
                                Contract Management is available to all individuals and organisations.
                            </p>
                            <span class="montserrat-bold">Here's what our Clients have to say about us.</span>
                        </template>
                    </short-section>
                </div>
                <div class="col-12 col-lg-8">
                    <swiper class="swiper" :options="swiperOption">
                        <swiper-slide v-for="(c,i) in clients" :key="i">
                            <div class="swiper-1 banner-container mb-5">
                                <div class="col fl-x fl-j-c fl-a-c">
                                    <short-section>
                                        <template #header>
                                            <img :src="c.img" alt="" class="round-custom avatar-img">
                                            <p class="mb-0 montserrat-medium">{{ c.name }}</p>
                                            <p class="fs--2 montserrat-light">{{ c.desig }}</p>
                                        </template>
                                        <template #content>
                                            <p>{{ c.desc }}</p>
                                        </template>
                                    </short-section>
                                </div>
                            </div>
                        </swiper-slide>
                        <div class="swiper-pagination" slot="pagination"></div>
                    </swiper>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ShortSection from '@/views/components/UI/ShortSection';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import clientImg from '../../assets/img/photo.png';
// import clientImg2 from '../../assets/img/placeholder.png';

export default {
    name: 'TestimonialsSection',
    components: {
        ShortSection,
        Swiper,
        SwiperSlide
    },
    data () {
        return {
            clientImg: clientImg,
            swiperOption: {
                // direction: 'vertical',
                slidesPerView: 1,
                // mousewheel: true,
                loop: false,
                // speed: 2,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 2
                    },
                    320: {
                        slidesPerView: 1
                    }
                }
            },
            clients: [
                {
                    img: clientImg,
                    name: 'Jojo Jose',
                    desig: 'MD - Navabavan Construction',
                    desc: 'ContractAce has reduced errors and discrepancies in our contracts, resulting in significant savings to the organisation'
                }
            ]
        };
    }
};
</script>

<style scoped>
/*.b-curved {*/
/*    border-radius: 1rem;*/
/*}*/

.avatar-img {
    width: 120px;
    height: auto;
}
</style>
