<template>
    <div class="home">
        <!--        <btn color="primary" text="simple" border-radius="custom"></btn>-->
        <section>
            <Banner/>
        </section>
        <section>
            <FeaturesSection></FeaturesSection>
        </section>
        <showcase-section></showcase-section>
        <testimonials-section></testimonials-section>
    </div>

</template>

<script>
import Banner from '../views/components/Banner';
import FeaturesSection from '@/views/components/FeaturesSection';
import ShowcaseSection from '@/views/components/ShowcaseSection';
import TestimonialsSection from '@/views/components/TestimonialsSection';

export default {
    name: 'Home',
    components: { TestimonialsSection, ShowcaseSection, FeaturesSection, Banner },
    metaInfo: {
        title: 'eProcurement | Construction Contract | eTender',
        titleTemplate: 'ContractAce | %s',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: 'ContractAce is an open and customisable eProcurement platform specifically designed for Construction & Engineering companies.'
            },
            {
                name: 'keywords',
                content: 'contractace, contract management services in India, contract management, e-tendering in India, construction software, procurement software, e-tenders, eprocurement, e-procurementprocurement ,procurement service in India'
            }
        ]
    }
};

</script>
<style scoped>

</style>
