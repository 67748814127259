<template>
    <main>
        <div class="">
            <div style="height: 100vh;">

                <swiper class="swiper" :options="swiperOption">

                    <swiper-slide>
                        <div class="swiper-2 banner-container">
                            <div class="col fl-x fl-j-c fl-a-c">
                                <section class="text-center text-white mt-banner">
                                    <h1 class="montserrat-bold">YOUR CONTRACT MANAGEMENT EXPERT</h1>
                                    <p class="font-weight-bold">CUSTOMISABLE AND OPEN PLATFORM FOR CONTRACT MANAGEMENT, PROCUREMENT AND
                                        E-TENDERING</p>
                                    <a target="_blank" href="https://admin.contractace.com/auth/landing/">
                                        <btn color="primary" size="lg" class="px-5 font-weight-bold" text="GET STARTED"
                                             border-radius="custom"></btn>
                                    </a>
                                </section>
                            </div>
                        </div>
                    </swiper-slide>
<!--                    <swiper-slide>-->
<!--                        <div class="swiper-2 banner-container">-->
<!--                            <div class="col fl-x fl-j-c fl-a-c">-->
<!--                                <section class="text-center text-white mt-banner">-->
<!--                                    <h1 class="montserrat-bold">CONTRACT</h1>-->
<!--                                    <p>CUSTOMISABLE AND OPEN PLATFORM FOR CONTRACT MANAGEMENT, PROCUREMENT AND-->
<!--                                        E-TENDERING</p>-->
<!--                                    <a target="_blank" href="https://contractaceuat.xeoscript.com/auth/landing/">-->
<!--                                        <btn color="primary" size="lg" class="px-5" text="GET STARTED"-->
<!--                                             border-radius="custom"></btn>-->
<!--                                    </a>-->
<!--                                </section>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </swiper-slide>-->
<!--                    <swiper-slide>-->
<!--                        <div class="swiper-1 banner-container">-->
<!--                            <div class="col fl-x fl-j-c fl-a-c">-->
<!--                                <section class="text-center text-white">-->
<!--                                    <h1 class="montserrat-bold">MANAGEMENT</h1>-->
<!--                                    <p>CUSTOMISABLE AND OPEN PLATFORM FOR CONTRACT MANAGEMENT, PROCUREMENT AND-->
<!--                                        E-TENDERING</p>-->
<!--&lt;!&ndash;                                    <btn color="primary" size="lg" class="px-5" text="GET STARTED"&ndash;&gt;-->
<!--&lt;!&ndash;                                         border-radius="custom"></btn>&ndash;&gt;-->
<!--                                    <a target="_blank" href="https://contractaceuat.xeoscript.com/auth/landing/">-->
<!--                                        <btn color="primary" size="lg" class="px-5" text="GET STARTED"-->
<!--                                             border-radius="custom"></btn>-->
<!--                                    </a>-->
<!--                                </section>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </swiper-slide>-->
<!--                    <div class="swiper-button-prev" slot="button-prev"></div>-->
<!--                    <div class="swiper-button-next" slot="button-next"></div>-->
                    <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
            </div>
        </div>
    </main>
</template>

<script>
import 'swiper/css/swiper.css';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

export default {
    name: 'Banner',
    components: {
        Swiper,
        SwiperSlide
    },
    data () {
        return {
            swiperOption: {
                // direction: 'vertical',
                slidesPerView: 1,
                // mousewheel: true,
                loop: false,
                // speed: 2,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            }
        };
    }
};

</script>

<style lang="scss" scoped>
.banner-container {
    height: 100vh !important;
    display: flex;
    position: relative;

    &:before {
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        background: rgba(0, 0, 0, 0.32);
    }

}

//@import './base.scss';

//main {
//    background-image: url("../../assets/img/banner.jpg");
//    background-size: cover;
//    background-position: 21% 34%;
//    display: flex;
//    flex-grow: 1;
//    min-height: 100vh;
//}

.swiper-1 {
    background-image: url("../../assets/img/banner/banner-01.jpg");
    background-size: cover;
    background-position: center;
    width: 100%;
    height: auto;
}

.swiper-2 {
    background-image: url("../../assets/img/banner/banner-02.jpg");
    background-size: cover;
    background-position: center;
    width: 100%;
    height: auto;
}

.swiper-slide {
    transition: all 300ms ease-in-out;
}

.swiper-button-prev, .swiper-button-next {
    color: var(--color-primary);
}
</style>
