<template>
    <div class="contact-us container">
        <div class="fl-eq bg-white bor-r-1 row m-0 px-lg-3">
            <div class="col-12 col-lg-4 contact-details p-4 bs-29 round-custom-2 text-secondary" data-aos="zoom-in"
                 data-aos-duration="400">
                <h5 class="mt-2 ">Contact Information</h5>
                <p class="team-desc font-weight-bold">Fill up the form</p>
                <div class="mt-2 font-weight-bold">
<!--                    <div class="fl-x-c mt-4">-->
<!--                        <p class="pl-2 m-0"><a class="mouseover-simple" href="tel:+91 7994000666"> <i-->
<!--                            class="fa fa-phone mr-3"></i> +91 7994000666</a></p>-->
<!--                    </div>-->
                    <div class="fl-x-c mt-4">
                        <p class="pl-2 m-0"><a target="_blank" href="mailto:support@contractace.com"
                                               class="mouseover-simple"> <i class="fa fa-envelope-open-o mr-3"></i>support@contractace.com</a>
                        </p>
                    </div>
                    <div class="fl-x-c mt-4">
                        <p class="pl-2 mb-0">
                            <a
                                class="mouseover-simple">
                                <i class="fa fa-map-marker mr-3"></i> Thrissur, Kerala, India.
                            </a>
                        </p>
                    </div>
                </div>
                <div class="mt-4 mt-lg-auto">
                    <ul class="fl-x-c social-media mt-2">
                        <a class="mr-2" target="_blank" href="https://www.facebook.com/ContractAce">
                            <i class="fa fa-facebook-f"></i>
                        </a>
<!--                        <a class="mr-2" target="_blank" href="#">-->
<!--                            <i class="fa fa-twitter"></i>-->
<!--                        </a>-->
                        <a class="mr-2" target="_blank" href="https://www.instagram.com/invites/contact/?i=1fbdsavsyz9j5&utm_content=ntgkunh">
                            <i class="fa fa-instagram"></i>
                        </a>
                        <a class="mr-2" target="_blank" href="https://www.linkedin.com/company/contractace/">
                            <i class="fa fa-linkedin"></i>
                        </a>
<!--                        <a class="mr-2" target="_blank" href="#">-->
<!--                            <i class="fa fa-youtube-play"></i>-->
<!--                        </a>-->
                    </ul>
                </div>
                <div class="plant-img">
                    <!--                    <img src="@/assets/images/plant.png" class="" alt="">-->
                </div>

            </div>
            <div class="col-12 col-lg-8 contact-form p-4 bs-4 round-custom-2" data-aos="fade-up"
                 data-aos-duration="500">
                <transition name="fade" mode="out-in">
                    <div class="text-center p-4 col-lg-10" v-if="state === 'S'" key="'S">
                        <div class="text-success">
                            <div class="text-center mb-4">
                                <i class="fa fa-check-square-o  fa-3x" aria-hidden="true"></i>
                            </div>
                            <div class="text-center">
                                <p class="fs-4">Thank you <strong>{{ name }}</strong> for contacting us</p>
                                We have received your enquiry and will respond to you within 24 hours.
                            </div>
                        </div>
                    </div>

                    <div class="text-center p-4 col-lg-10" v-else-if="state === 'F'" key="'F">
                        <div class="text-danger">
                            <div class="text-center mb-4">
                                <i class="fa fa-window-close  fa-3x" aria-hidden="true"></i>
                            </div>
                            <div class="text-center">
                                <p class="fs-4"><strong>OOPS!</strong></p>
                                Something has gone wrong, Please try again.
                            </div>
                        </div>
                    </div>
                    <div class="alert alert-info text-secondary" v-else-if="state === 'L'" key="'L">
                        <div class="d-flex justify-content-center align-items-center">
                            <div class="text-center p-4">
                                <loading-animation/>
                                <p class="c-font-19 mb-4 proxim-light">Please wait while communicate with the
                                    server</p>
                            </div>
                        </div>
                    </div>
                    <s-form class="contact-form row text-secondary" v-else role="form" @submit="submit"
                            autocomplete="off">
                        <div class="col-12 col-lg-6">
                            <validated-input border-radius="custom" name="Name" v-model="model.name" shadow="0"
                                             placeholder="" label="Name" :rules="rules.name1"
                                             required/>
                        </div>
                        <div class="col-12 col-lg-6">
                            <validated-input border-radius="custom" type="email" name="Email" v-model="model.email"
                                             shadow="0"
                                             placeholder="" label="Email" :rules="rules.email_id"/>
                        </div>
                        <div class="col-12 col-lg-6">
                            <validated-mask-input border-radius="custom" name="Phone" v-model="model.phone" shadow="0"
                                                  mask="##########" placeholder="" label="Phone"
                                                  :rules="rules.phone_number"/>
                        </div>
                        <div class="col-12 col-lg-6">
                            <validated-input border-radius="custom" name="City" v-model="model.city" shadow="0"
                                             placeholder="" label="City" :rules="rules.city1"
                                             required/>
                        </div>
                        <div class="col-12">
                            <div class=" mb-4">
                                <validated-text-area border-radius="custom" label="Message" v-model="model.message"
                                                     :rules="rules.message1"
                                                     shadow="0"/>
                            </div>
                            <div class="mt-5 text-right">
                                <btn text="Submit" loading-text="submiting.." :loading="loading" border-radius="custom"
                                     class="w-50" type="submit"></btn>
                            </div>
                        </div>

                    </s-form>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'secure-axios';
import urls from '@/data/urls';
import LoadingAnimation from './LoadingAnimation';

export default {
    name: 'ContactUs',
    props: {},
    components: { LoadingAnimation },
    data () {
        return {
            loading: false,
            state: 'D',
            name: '',
            email: '',
            phone: '',
            city: '',
            message: '',
            model: {
                name: '',
                email: '',
                city: '',
                phone: '',
                message: ''
            },
            rules: {
                name1: {
                    required: true
                },
                email_id: {
                    required: true
                },
                phone_number: {
                    required: true,
                    min: 10,
                    max: 10
                },
                city1: {
                    required: true
                },
                message1: {
                    required: true
                }
            }
        };
    },
    methods: {
        async submit () {
            const that = this;
            this.name = that.model.name;
            that.state = 'L';
            const response = await axios.form(urls.admin.ContactUs.email, that.model);
            if (response.data.success) {
                that.state = 'S';
                // that.$notify('Enquiry submitted successfully', 'Success', {
                //     type: 'success'
                // });
                that.loading = false;
                // that.model.name = '';
                // that.model.email = '';
                // that.model.city = '';
                // that.model.phone = '';
                // that.model.message = '';
            } else {
                // that.$notify('Something went wrong', 'Error', {
                //     type: 'danger'
                // });
                that.state = 'F';
            }
        },
        onSubmit () {
            const bodyFormData = new FormData();
            bodyFormData.append('name', this.name);
            bodyFormData.append('email', this.email);
            bodyFormData.append('phone', this.phone);
            bodyFormData.append('city', this.city);
            bodyFormData.append('message', this.message);

            const that = this;
            this.state = 'L';

            axios({
                method: 'post',
                url: 'https://contractacefront.xeoscript.com/mail.php',
                data: bodyFormData,
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(function (response) {
                that.onSubmitSuccess(response);
            }).catch(function (response) {
                that.onSubmitFailed(response);
            });
        },

        onSubmitSuccess () {
            this.state = 'S';
        },

        onSubmitFailed () {
            this.state = 'F';
        }
    }
};
</script>
<style>

</style>

<style scoped lang="scss">
a {
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

.contact-details {
    color: #fff;
    background-color: var(--color-primary);
    /*border-radius: 0.75rem;*/
    margin: 45px 0;
    position: relative;
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;

    &:before {
        content: "";
        background-image: url("../../assets/img/Shape.png");
    }
}

.plant-img {
    position: absolute;
    bottom: 1em;
    right: 1.4em;
}

.contact-us {
    padding-left: 5rem;
    padding-right: 5rem;
    justify-content: center;
}

.contact-details img {
    width: 15px;
    /*height: 15px;*/
}

.plant-img img {
    width: 51px;
    height: auto;
}

.color-dark {
    color: #46484b;
}

.social-media .social-icon,
.social-media-mobile .social-icon {

    /*height: 15px;*/
    width: 25px;
}

.contact-us p {
    font-size: 0.9rem;
}

.mouseover-simple:hover,
.mouseover-simple:active {
    color: #fff;
}

.bor-r-1 {
    border-radius: 0.5em;
}

.social-media a:not(:first-child),
.social-media-mobile a:not(:first-child) {
    padding-left: 0.5em;
}

.contact-form {
    background-color: #fff;
    margin-top: -1rem;
    padding-top: 1.5rem;
    /*border-radius: .75rem;*/
    align-items: center;
    justify-content: center;
    display: flex;
    min-height: 600px;
}

.xeo-radio-1 {
    margin-left: 0.5em;
    font-size: 13px;
    color: #46484b;
}

label {
    font-weight: 600 !important;
    font-size: 0.9em !important;
}

.xeo-radio-1 input:checked {
    font-weight: 600;
}

.xeo-radio-1 .checkmark:after {
    left: 7px;
    top: 4px;
    width: 6px;
    height: 10px;
}

.xeo-radio-1 .checkmark {
    width: 20px;
    height: 20px;
}

//.team-desc {
//    color: cornsilk;
//}

.w-50 {
    width: 35%;
}

@media screen and (min-width: 992px) {
    .contact-form {
        background-color: #fff;
        /*border-radius: 0 .75rem .75rem 0;*/
        margin-left: -22px;
        padding-left: 30px;
        margin-top: 0;
        padding-top: 0;
        min-height: 393px;
    }

}

@media screen and (max-width: 992px) {
    .contact-us .bg-white {
        flex-direction: column;
    }

    .contact-us {
        padding-left: 1em;
        padding-right: 1em;
    }

    .w-50 {
        width: 100%;
    }

}

@media screen and (max-width: 768px) {
    .contact-details {
        width: 100%;
    }
}
</style>
