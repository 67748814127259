<template>
    <section class="bg-third py-3 py-lg-7 of-h">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-6" data-aos="slide-left" data-aos-duration="500">
                    <img src="../../assets/img/banner/banner-02.jpg" alt="" class="img-fluid full-width d-block">
                </div>
                <div class="col-12 col-lg-6 mb-3 mb-lg-0 order-first" data-aos="slide-right" data-aos-duration="500">
                    <div class="fl-y h-100 sm-tx-c">
                        <short-section title-size="lg" class="mb-3"
                                       heading="360° SOLUTION FOR TENDERING, CONTRACT MANAGEMENT AND PROCUREMENT">
                            <template #content>
                                <p>
                                    The platform's Open Architecture is designed for preparation of complex Engineering &
                                    Construction Contracts.
                                </p>
                            </template>
                        </short-section>
                        <div class="text-center text-lg-left mt-auto">
                            <a target="_blank" href="https://contractaceuat.xeoscript.com/auth/landing/">
                                <btn color="primary" class="half-width font-weight-bold" text="Get Started"
                                 border-radius="custom"></btn>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ShortSection from '@/views/components/UI/ShortSection';

export default {
    name: 'ShowcaseSection',
    components: { ShortSection }
};
</script>

<style scoped>

</style>
