<template>
    <div>
        <InnerBanner bg="main" heading="Packages"/>
        <section class="py-5">
            <!--            <div class="get-in-touch mb-4">-->
            <!--                <div class="container">-->
            <!--                    <div class="row fl-y-c py-3">-->
            <!--                        <div class="col-10 text-center get-in-text">-->
            <!--                            <h2 class="font-weight-bold text-primary">Packages</h2>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->
            <div class="container">
                <div class="row">
                    <div class="col-md-6 col-lg-4 mb-4 mb-lg-0">
                        <div class="h-100 b-2 round-2 of-h">
                            <div class="bg-third p-3 text-center font-weight-bold top-section">
                                <h4 class="font-weight-bold mt-2">Package 1</h4>
                                <hr class="bg-secondary b-1">
                                <!--                                <p class="mb-0 fs&#45;&#45;1">This basic package is suitable for individuals and those requiring a-->
                                <!--                                    small contractor to-->
                                <!--                                    execute a small item of work or for small restoration project. [e.g. Additional-->
                                <!--                                    rooms, Compound wall, etc.]</p>-->
                                <p class="mb-0 fs--1 mt-5">This basic package is suitable for suppliers (material, equipment,
                                    manpower hire, etc.)
                                    and those providing services to engineering and construction industry</p>
                            </div>
                            <div class="p-3 overlay-pack">
                                <ul class="list-unstyled c-packages">
                                    <li>Register your company on ContractAce
                                    </li>
                                    <li>Branding area - Create your company profile, enchance visibility and get more
                                        enquiries
                                    </li>
                                    <li>Recieve "Request for Bids" from clients</li>
                                    <li>Send tender queries and get response from clients.This can be made a part of
                                        contract document
                                    </li>
                                    <li>Submit price bids</li>
                                    <li>Printing and signing contract</li>
                                </ul>
                                <a href="https://contractaceuat.xeoscript.com/auth/register/" target="_blank"
                                   class="text-center mt-auto">
                                    <btn text="Free " class="px-4" border-radius="custom"/>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 mb-4 mb-lg-0">
                        <div class="h-100 b-2 round-2 of-h">
                            <div class="bg-third p-3 text-center font-weight-bold top-section">
                                <h4 class="font-weight-bold mt-2">Package 2</h4>
                                <hr class="bg-secondary b-1">
                                <p class="mb-0 fs--1 mt-5">This package is suitable for small/medium size organisations
                                    (contractors,
                                    consultants and clients) have regular work for freelance contract managers or
                                    procurement
                                    experts</p>
                            </div>
                            <div class="p-3 h-100 overlay-pack">
                                <ul class="list-unstyled c-packages">
                                    <li>This package includes all features of package-1</li>
                                    <li>
                                        Option to create new tenders
                                    </li>
                                    <li>Finding vendors / contractors online
                                    </li>
                                    <li>Sending tenders to select agencies</li>
                                    <li>Receiving / collecting bids</li>
                                    <li>Tender clarification. Receive tender queries and respond privately to these queries.
                                        This can be made a part of the contract document
                                    </li>
                                    <li>Provision for comparison / analysis of bids received</li>
                                    <li>
                                        Receive revised quote from specific vendors after negotiation
                                    </li>
                                    <li>Copy any previous tender, modify it and use it for upcoming projects</li>
                                </ul>
                                <a href="https://contractaceuat.xeoscript.com/auth/register/" target="_blank"
                                   class="text-center mt-auto">
                                    <btn text="Free Trial" border-radius="custom"/>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 mb-4 mb-lg-0">
                        <div class="h-100 b-2 round-2 of-h">
                            <div class="bg-third p-3 text-center font-weight-bold top-section">
                                <h4 class="font-weight-bold mt-2">Package 3</h4>
                                <hr class="bg-secondary b-1">
                                <p class="mb-0 fs--1 mt-5">This package is suitable for medium to large organisations
                                    (contractors, consultants, freelancers and clients) with regular works</p>
                            </div>
                            <div class="p-3 h-100 overlay-pack">
                                <ul class="list-unstyled c-packages">
                                    <li>This package includes all the features of package-2
                                    </li>
                                    <li>Option to create customised templates for your organisation
                                    </li>
                                    <li>Add employees / sub-users. Employees can be added
                                        to procurement/ tendering team and specific roles allocated
                                    </li>
                                    <li>Modify a previous tender or use a customised template for an upcoming project
                                    </li>
                                    <li>Import and export tender documents from third party such as freelance contract
                                        managers
                                    </li>
                                </ul>
                                <a href="https://contractaceuat.xeoscript.com/auth/register/" target="_blank"
                                   class="text-center mt-auto">
                                    <btn text="Free Trial" border-radius="custom"/>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    </div>
</template>

<script>

import InnerBanner from '@/views/components/Inner-banner';

export default {
    name: 'Packages',
    components: {
        InnerBanner
    },
    metaInfo: {
        title: 'Packages',
        titleTemplate: 'ContractAce | %s',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: 'We have 4 packages. You can choose according to your needs.'
            },
            {
                name: 'keywords',
                content: 'contractace, eprocurement, e-procurement, e-tendering in India, e-tenders, contract management services , contract management in India, procurement, construction software, procurement software, procurement service in India, procurement process in India'
            }
        ]
    }
};
</script>

<style scoped lang="scss">
.top-section {
    min-height: 270px;
}

.overlay-pack {
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.29);
    height: calc(100% - 270px);
}

.c-packages {
    font-size: 0.9rem;
    margin-bottom: 1rem;

    li {
        position: relative;
        padding-left: 25px;
        margin-bottom: 15px;

        &:before {
            content: "\f00c";
            font-family: FontAwesome, serif;
            position: absolute;
            left: 0;
            top: 0;
            color: var(--color-primary);
        }
    }
}
</style>
