<template>
    <div>
        <InnerBanner bg="main" heading="Contractor"/>
        <details-inner heading="For Contractors / Subcontractors ">
            <template #content>
                <p>
                    This platform is designed by experts in the field and can substantially improve the contract
                    management, procurement and tendering capabilities of the organisation which is vital for the
                    survival of any contracting company. The systems are designed to be simple, economic and modular and
                    can be implemented with minimum learning.
                    <br><br>
                    The platform provides the following advantages :
                </p>
                <ul class="ml-4">
                    <li>
                        ContractAce provides an open and highly customisable e-procurement platform where the contractors
                        can prepare tenders online. This can be then send it to subcontractors, suppliers or hiring
                        agencies for getting most competitive prices.
                    </li>
                    <li>
                        Online presence improves visibility to clients and increases chances of getting more
                        tenders.
                    </li>
                    <li>
                        The high quality standard templates provided by ContractAce, can substantially improve the standard of
                        the contracts issued and also improve the efficiency of the contract management team. It can
                        reduce errors and oversight in the contract consequently reducing risk of losses and disputes.
                    </li>
                    <li>
                        Once the contract management and procurement processes are moved online, the management can
                        audit it at any point of time. This greatly enhances the transparency of the process.
                    </li>
                    <li>
                        Historical data can be easily accessed and analysed. Thus consistently building the company’s
                        knowledge base.
                    </li>
                    <li>
                        The system being cloud based can be accessed, reviewed, and updated from anywhere. A single
                        contract manager or a small group can manage multiple projects spread over large geographies.
                    </li>
                </ul>
                <br>
                <p>The following options are suitable for contractors:</p>
                <ol class="ml-4">
                    <li><span class="montserrat-bold">Option-1</span>: &nbsp;Create tender documents from the standard
                        templates provided, send it for pricing, collect bids and prepare contracts.
                    </li>
                    <li><span class="montserrat-bold">Option-2</span>:&nbsp; Create  customised template
                        according to the organisational needs, which can be modified and used for making new contracts.

                    </li>
                    <li>
                        <span class="montserrat-bold">Optiom-3 </span>:&nbsp;Avail the services of our contract management
                        expert to design and develop a suite of high quality professional standard templates that can be
                        used for various projects. This can reduce the time and effort needed to raise the
                        quality of the contract management system to global standards.
                    </li>
                </ol>
            </template>
            <template #footer>
                <Otherservices/>
                <!--                <div class="row">-->
                <!--                    <div class="col">-->
                <!--                        <div class="fl-x fl-j-sb">-->
                <!--                            <router-link :to="{name:'ContractManagers-Service-Detials'}">Prev</router-link>-->
                <!--                            <router-link :to="{name:'Client-Service-Detials'}">Next</router-link>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </div>-->
            </template>
        </details-inner>
    </div>
</template>

<script>
import InnerBanner from '@/views/components/Inner-banner';
import DetailsInner from '@/views/components/UI/DetailsInner';
import Otherservices from '@/views/components/Otherservices';

export default {
    name: 'ContractorService',
    components: { Otherservices, DetailsInner, InnerBanner },
    metaInfo: {
        title: 'Contractor services',
        titleTemplate: 'ContractAce | %s',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: 'ContractAce provides a full 360 degree solution for Tendering, Contract Management & Procurement.'
            },
            {
                name: 'keywords',
                content: 'contractace, e-tendering in India, e-tenders, eprocurement, e-procurement, contract management services , contract management in India, procurement software, construction software, procurement service , procurement process in India'
            }
        ]
    }
};
</script>

<style scoped>

</style>
