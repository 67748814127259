<template>
    <div>
        <InnerBanner bg="main" heading="Client"/>
        <details-inner heading="For Individuals & Organisations">
            <template #content>
                <p>
                    We provide guidance and assistance to <span class="montserrat-bold">Individuals</span> and <span
                    class="montserrat-bold">and small business</span> owners who are not
                    familiar with the construction process and those wanting to undertake construction projects.
                    This could be construction of a house for your family, a shop or a warehouse for traders, etc.
                    We at ContractAce will assist you to prepare a suitable Contract where your rights are
                    protected and risks  reduced. The following
                    services are available for home builders and those planning to undertake construction works:

                </p>
                <ol class="ml-4">
                    <li>
                        <span class="montserrat-bold">Option-1 </span>:&nbsp; Use the existing professionally drafted
                        standard templates to reduce risks. This has to be modified as per
                        specific project requirements.

                    </li>
                    <li>
                        <span class="montserrat-bold">Option-2</span>:&nbsp;Avail the service of our contract management expert to decide the best procurement strategy
                        and develop high quality professional standard contract document.

                    </li>
                </ol>
                <br>
                <p>There are many options available for  construction depending on their needs, the amount
                    of involvement they would like to have in the project and the risk they are willing to take, such as
                    :
                </p>
                <ol class="ml-4">
                    <li>Design & Build</li>
                    <li>Design, Bid & Build</li>
                    <li>Lumpsum Contract</li>
                    <li>Re-measurable Contract</li>
                    <li>Cost Plus Contracts.</li>
                </ol>
                <br>
                <p>
                    In this regard, our experts can assist you with the above options, removing your confusions,
                    crystallising your requirements and advising suitable procurement strategy / contract form to be
                    followed for the project.

                    <br>
                    <br>
                    ContractAce also provides the following advantages to clients:
                </p>
                <ul class="ml-4">
                    <li>
                        Avail the services of a contract management expert for your specific project requirement.
                    </li>
                    <li>
                        High quality standard templates which can substantially improve the quality of your contract
                        documents.
                    </li>
                    <li>
                        It can reduce errors and oversight in the contract, consequently reducing risk of losses &
                        disputes.
                    </li>
                    <li>
                        Being online considerably increases your chances of finding more contractors providing the
                        required services, thus ensuring better value for money.
                    </li>
                    <li>
                        Improves transparency of the process.
                    </li>
                </ul>
            </template>
            <template #footer>
                <Otherservices/>
                <!--                <div class="row">-->
                <!--                    <div class="col">-->
                <!--                        <div class="fl-x fl-j-sb">-->
                <!--                            <router-link :to="{name:'Contractors-Service-Detials'}">Prev</router-link>-->
                <!--                            <router-link :to="{name:'Suppliers-Service-Detials'}">Next</router-link>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </div>-->
            </template>
        </details-inner>
    </div>

</template>

<script>
import DetailsInner from '@/views/components/UI/DetailsInner';
import InnerBanner from '@/views/components/Inner-banner';
import Otherservices from '@/views/components/Otherservices';

export default {
    name: 'ClientService',
    components: { Otherservices, InnerBanner, DetailsInner },
    metaInfo: {
        title: 'Client services ',
        titleTemplate: 'ContractAce | %s',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: 'ContractAce provides a full 360 degree solution for Tendering, Contract Management & Procurement.'
            },
            {
                name: 'keywords',
                content: 'contractace, e-tendering in India, e-tenders, eprocurement, e-procurement, contract management services , contract management in India, procurement software, construction software, procurement service , procurement process in India'
            }
        ]
    },
    props: {
        prev: {
            type: String,
            default: '/'
        },
        next: {
            type: String,
            default: '/'
        }
    },
    mounted () {
        console.log('routes nav', this.$route.params, this.$route.path);
    }
};
</script>

<style scoped>

</style>
