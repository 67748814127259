<template>
    <section class="bg-third py-5 py-lg-7">
        <div class="container">
            <div class="row service-section">
                <service-item class="col-12 col-md-6" :class="{'mb-5':i < services.length - 1}"
                              v-for="(p,i) in services" :key="i" :img="p.img" :content="p.content"
                              :heading="p.title" :link="p.link" :next-link="p.next" :prev-link="p.prev"></service-item>
            </div>
        </div>
        <!--        <btn text="router test" @click="router('def')"></btn>-->
    </section>
</template>

<script>
import clientImg from '../../assets/img/services/service-01.png';
import consultImg from '../../assets/img/services/service-02.png';
import contractImg from '../../assets/img/services/service-03.png';
import supplierImg from '../../assets/img/services/service-04.png';
import contractManageImg from '../../assets/img/services/service-05.png';

import ServiceItem from '@/views/components/UI/ServiceItem';

export default {
    name: 'servicesection',
    components: {
        ServiceItem
    },
    mounted () {
        this.setNavigationLinks();
    },
    data () {
        return {
            services: [
                {
                    title: 'Clients',
                    img: clientImg,
                    content: 'For individuals and businesses planning to undertake construction projects',
                    link: '/Client-Service-Detials/',
                    prev: '',
                    next: ''
                },
                {
                    title: 'Consultants',
                    img: consultImg,
                    content: 'For consultants providing engineering and commercial services to clients',
                    link: '/Consultant-Service-Detials/',
                    prev: '',
                    next: ''

                },
                {
                    title: 'Contractor',
                    img: contractImg,
                    content: 'For general contractors and subcontractors executing construction projects for clients',
                    link: '/Contractors-Service-Detials/',
                    prev: '',
                    next: ''

                },
                {
                    title: 'Suppliers',
                    img: supplierImg,
                    content: 'For material suppliers, equipment manufactures and manpower hiring agencies',
                    link: '/Suppliers-Service-Detials/',
                    prev: '',
                    next: ''

                },
                {
                    title: 'Contract Managers',
                    img: contractManageImg,
                    content: 'For procurement managers and contract managers providing  freelance services to clients ',
                    link: '/ContractManagers-Service-Detials/',
                    prev: '',
                    next: ''

                }
            ]
        };
    },
    methods: {
        setNavigationLinks () {
            for (let i = 0; i < this.services.length; i++) {
                if (i > 0 && i < this.services.length - 1) {
                    // Not first and last item
                    this.services[i].prev = this.services[i - 1].link;
                    this.services[i].next = this.services[i + 1].link;
                } else if (i === this.services.length - 1) {
                    this.services[i].prev = this.services[i - 1].link;
                    this.services[i].next = this.services[0].link;
                } else if (i === 0) {
                    this.services[i].prev = this.services[this.services.length - 1].link;
                    this.services[i].next = this.services[i + 1].link;
                }
            }
        },
        router (item) {
            this.$router.push({ path: 'abc/def/' + item + '/' });
        }
    }
};
</script>

<style scoped>

</style>
