<template>
    <div>
        <router-link class="sm-fl-y-lg-fl-x" :to="{path:link, params:{next:nextLink,prev:prevLink}}">
            <div class="min-w-40">
                <img :src="img" alt="" class="img-fluid">
            </div>

            <short-section :heading="heading">
                <template #content>
                    <p>
                        {{ content }}
                    </p>
                </template>
            </short-section>
        </router-link>
    </div>
</template>

<script>
import ShortSection from '@/views/components/UI/ShortSection';

export default {
    name: 'PeopleItem',
    components: { ShortSection },
    props: {
        heading: {
            type: String,
            default: ''
        },
        img: {
            type: Image
        },
        content: {
            type: String,
            default: ''
        },
        link: {
            type: String,
            default: '/'
        },
        nextLink: {
            type: String,
            default: '/'
        },
        prevLink: {
            type: String,
            default: '/'
        }

    }
};
</script>

<style lang="scss" scoped>
a {
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}
</style>
