<template>
    <div>
        <InnerBanner bg="main" heading="CONTACT"/>
        <section class="py-5">
            <div class="get-in-touch">
                <div class="container">
                    <div class="row fl-y-c py-3">
                        <div class="col-10 text-center get-in-text">
                            <h2 class="font-weight-bold">Get In Touch</h2>
                            <p>Let us know how we can help and set up a time to chat with us!</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">

                <Contactus/>

            </div>
        </section>
    </div>
</template>

<script>
import InnerBanner from '@/views/components/Inner-banner';
import Contactus from '@/views/components/ContactUs';

export default {
    name: 'Contact',
    components: { Contactus, InnerBanner },
    metaInfo: {
        title: 'Contact us',
        titleTemplate: 'ContractAce | %s',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: 'Drop a mail at support@contract.com for all your queries or fill the form in the contact page and we will reach you.'
            },
            {
                name: 'keywords',
                content: 'contractace, eprocurement, e-procurement, contract management services, contract management, e-tendering, e-tenders, construction software in India, procurement software, procurement, procurement process , procurement service in India'
            }
        ]
    }
};
</script>

<style scoped lang="scss">
.get-in-touch {
    position: relative;
    //margin-bottom: 150px;

    svg {
        position: absolute;
        width: 100%;
        //left: 0;
        //bottom: 0;
    }

    .get-in-text {

    }
}

</style>
