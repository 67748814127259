<template>
    <div class="inner-banner" :class="['bg-'+ bg]">
        <div class="container">
            <div class="row py-8 fl-y-c ">
                <div class="col-lg-8 text-center">
                    <h2 class="text-uppercase mt-banner" v-if="heading" v-html="heading">Title</h2>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'Inner-banner',
    props: {
        bg: {
            type: String,
            required: false,
            default: ''
        },
        heading: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>

<style scoped lang="scss">

.inner-banner {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.49);
    }

    h2 {
        font-size: 2rem;
        font-weight: 600;
        color: white;
    }

    @media (min-width: 992px) {

        h2 {
            font-size: 2.5rem;
        }
    }

}

.bg-main {
    background-image: url(../../assets/img/bg-1.png);
}
</style>
