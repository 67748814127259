<template>
    <div>
        <InnerBanner bg="main" heading="Suppliers"/>

        <details-inner heading="For Material Suppliers, Equipment Hiring Agencies and Manpower Supply Companies">
            <template #content>
                <p>
                    This platform is designed by experts in the field and can substantially improve the contract
                    management, procurement and tendering capabilities of the organisation. The systems are designed to
                    be simple, economic and modular and can be implemented with minimum learning.
                    <br><br>
                    ContractAce provides the following advantages :
                </p>
                <ul class="ml-4">
                    <li>
                        Online presence  improves visibility to clients and increases chances of getting more
                        enquiries.
                    </li>
                    <li>
                         ContractAce provides an open and highly customisable e-procurement ans e-tendering platform where one can do their
                        entire sourcing online and obtain the most competitive prices.
                    </li>
                    <li>
                         High quality templates are available for growing organisations which can
                        improve the standard of the contracts issued. It also improves the efficiency of
                        the procurement / contract management team.
                    </li>
                    <li>
                        Reduce errors and oversight in the contract, consequently minimising risk of losses and disputes.
                    </li>
                    <li>
                        Once the processes are moved online, the management can audit it at any point of time. This
                        greatly enhances the transparency of the process.
                    </li>
                    <li>
                        Historical data can be easily accessed and analysed. Thus consistently building the company's
                        knowledge base.
                    </li>
                    <li>
                        The system being cloud based can be accessed from anywhere. Hence, making it possible for a small
                        group to manage multiple projects spread over large geographies.
                    </li>
                </ul>
                <br>
                <p>The following options are suitable for suppliers & hiring agencies:</p>
                <ol class="ml-4">
                    <li><span class="montserrat-bold">Option-1</span>:&nbsp; Create tender documents from the standard
                        templates provided, send it for pricing, collect bids and prepare contracts.
                    </li>
                    <li><span class="montserrat-bold">Option-2</span>:&nbsp; Create customised templates
                        according to the organisational needs, which can be modified and used for making new contracts.
                    </li>
                    <li>
                        <span class="montserrat-bold">Option-3</span>:&nbsp; Avail the services of our contract management
                        expert to design & develop a suite of high quality professional standard templates that can be
                        used for various projects. This can reduce the time and effort needed to raise the
                        quality of the contract management system to global standards.
                    </li>
                </ol>
            </template>
            <template #footer>
                <Otherservices/>
                <!--                <div class="row">-->
                <!--                    <div class="col">-->
                <!--                        <div class="fl-x fl-j-sb">-->
                <!--                            <router-link :to="{name:'Client-Service-Detials'}">Prev</router-link>-->
                <!--                            <router-link :to="{name:'Consultant-Service-Detials'}">Next</router-link>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </div>-->
            </template>
        </details-inner>
    </div>
</template>

<script>
import DetailsInner from '@/views/components/UI/DetailsInner';
import InnerBanner from '@/views/components/Inner-banner';
import Otherservices from '@/views/components/Otherservices';

export default {
    name: 'SupplierService',
    components: { Otherservices, InnerBanner, DetailsInner },
    metaInfo: {
        title: 'Supplier services',
        titleTemplate: 'ContractAce | %s',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: 'ContractAce provides a full 360 degree solution for Tendering, Contract Management & Procurement.'
            },
            {
                name: 'keywords',
                content: 'contractace, e-tendering in India, e-tenders, eprocurement, e-procurement, contract management services , contract management in India, procurement software, construction software, procurement service , procurement process in India'
            }
        ]
    }
};
</script>

<style scoped>

</style>
